<template>
  <q-menu
    @before-show="storeToast.fetchListNotification()"
    class="notification-menu"
    ref="notificationMenu"
    transition-show="jump-down"
    transition-hide="jump-up"
    style="overflow: hidden"
  >
    <q-item header class="notification-menu-header">
      <q-item-section class="notification-menu-header-section">
        <p class="no-margin">Уведомления</p>
        <nav>
<!--          <q-icon style="cursor: pointer" name="settings" size="20px" color="dark" />-->
          <q-icon style="cursor: pointer" v-close-popup name="close" size="20px" color="dark" />
        </nav>
      </q-item-section>
    </q-item>
    <q-separator />

    <q-list style="position: relative">
      <div style="height: 200px" v-if="allNotifications.length == 0">
        <q-inner-loading style="z-index: 999" :showing="true">
          <custom-loader />
        </q-inner-loading>
      </div>
      <div v-else class="custom-scroll scroll" style="max-height: 450px" ref="scrollTargetRef">
        <q-infinite-scroll
          @load="onLoadnewPage"
          ref="infiniteScrollRef"
          :offset="250"
          :scroll-target="scrollTargetRef"
        >
          <q-item v-for="(item, index) in allNotifications">
            <q-item-section
              :data-item="JSON.stringify(item)"
              class="notification-menu-box"
              @click="storeToast.makeRead(item.id)"
            >
              <q-avatar size="40px">
                <img src="~/assets/img/AI/serafima.png" alt="img" />
              </q-avatar>
              <div
                class="notification-menu-box-info"
                :data-item="JSON.stringify(item)"
                v-intersection="handleScroll"
              >
                <span>
                  <p class="no-margin text-regular">Серафима</p>
                  <p class="no-margin text-pale">
                    {{ formatTime(item.created_at) }}
                  </p>
                </span>
                <p class="no-margin text-message">
                  {{ item.title }}
                </p>
                <p class="no-margin text-message" v-html="item.description"></p>
              </div>
              <div v-if="!item.is_read" class="notification-menu-box-isread">
                <q-icon name="circle" color="primary" size="8px" />
              </div>
            </q-item-section>
          </q-item>
          <template v-slot:loading>
            <div class="row justify-center q-my-md">
              <q-spinner-dots color="primary" size="40px" />
            </div>
          </template>
        </q-infinite-scroll>
      </div>
    </q-list>
  </q-menu>
</template>

<script setup lang="ts">
import { formatTime } from '~/helpers/Dates/formatTime'
import { ToastStore } from '~/store/toastStore'

definePageMeta({
  middleware: 'auth',
})

const storeToast = ToastStore()
const notificationMenu = ref(null)
const scrollTargetRef = ref()
const infiniteScrollRef = ref()
const allNotifications = computed(() => storeToast.$state.allNotifications)

const handleScroll = (entry: IntersectionObserverEntry) => {
  const el = entry.target
  const itemData = el.getAttribute('data-item')
  if (itemData) {
    const unmaskedItem = JSON.parse(itemData)
    if (!unmaskedItem.is_read) {
      storeToast.makeRead(unmaskedItem.id)
    }
  }
}

const onLoadnewPage = async (index: any, done: any) => {
  if (
    storeToast.$state.notifyDisplay.currentPage != storeToast.$state.notifyDisplay.totalPages + 1 &&
    storeToast.$state.notifyDisplay.totalPages != 1
  ) {
    await storeToast.fetchNextPageNotification()
    done()
  } else {
    infiniteScrollRef.value.stop()
  }
}
onMounted(() => {
  storeToast.$state.ShowlistNotifications = notificationMenu.value
})
</script>

<style lang="scss">
.notification-menu {
  width: 420px;
  right: 0 !important;
  left: unset !important;
  top: 7.5% !important;
  &-header {
    height: 44px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    &-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: fit-content;
    }
    &-section > p {
      color: var(--slate-800);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
    }
    &-section > nav {
      display: flex;
      gap: 12px;
    }
  }
  &-box {
    width: 396px;
    padding: 12px;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 0.1fr 1fr 0.01fr;
    justify-content: flex-start;
    cursor: pointer;
    &-info {
      display: grid;
      margin-left: 12px;
    }
    &-info > span {
      display: flex;
      gap: 8px;
    }
    &-isread {
      align-self: center;
    }
  }
  &-box:hover {
    background: var(--slate-100);
  }
}

.text {
  &-pale {
    color: var(--slate-500);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  &-regular {
    color: var(--slate-800);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
  &-message {
    color: var(--slate-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    word-break: break-word;
  }
}
.custom-scroll {
  scrollbar-width: thin;
  margin-right: 5px;
}
.custom-scroll::-webkit-scrollbar {
  width: 4px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 80px;
  background-color: var(--slate-200);
}
</style>
